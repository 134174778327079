import { useState, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AuthRoute } from "../auth/routes/AuthRoute";
import { useAuthStore } from "../hooks/useAuthStore";
import { TecRoutes } from "../tec/routes/tecRoutes";

import { Dialog, DialogTitle, DialogContent, Box } from "@mui/material";
import ThemeProviderComponent from "../theme/Theme";

export const AppRouter = () => {
  const { status, checkAuthToken, user } = useAuthStore();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    checkAuthToken();
  }, [checkAuthToken]);

  useEffect(() => {
    const hasShownModal = localStorage.getItem("hasShownModal");
    if (status === "authenticated" && !hasShownModal) {
      setShowModal(true);
    }
  }, [status]);

  const closeModal = () => {
    setShowModal(false);
    localStorage.setItem("hasShownModal", "true");
  };

  if (status === "checking") {
    return (
      <div className="contenedorLoading">
        <div className="conternedorContentLoading">
          <div className="">
            <span className="loader"></span>
          </div>
          <div className="">
            <span className="cargando">Cargando....</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <ThemeProviderComponent>
      <Routes>
        {status === "not-authenticated" ? (
          <>
            <Route path="/auth/*" element={<AuthRoute />} />
            <Route path="/*" element={<Navigate to="/auth/login" />} />
          </>
        ) : (
          <>
            <Route path="/*" element={<TecRoutes />} />
            <Route path="/*" element={<Navigate to="/" />} />
          </>
        )}
      </Routes>
      {showModal &&
        status === "authenticated" &&
        user.departmentName !== "Departamento Cliente" && (
          <Modal onClose={closeModal} user={user} />
        )}
    </ThemeProviderComponent>
  );
};

const Modal = ({ onClose, user, pendiente, solicitud }) => {
  useEffect(() => {
    const timeoutId = setTimeout(onClose, 4000);

    return () => clearTimeout(timeoutId);
  }, [onClose]);

  return (
    <Dialog
      open={true}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: "#f0f0f0",
          borderRadius: "10px",
          borderColor: "red",
        },
      }}
    >
      <DialogTitle>Bienvenido {user.name}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {[
            "Recepcion de boletas de servicio",
            "Banco Bisa S.A., 28 cada mes",
            "Banco BMSC S.A., 04 cada mes",
            "DMC S.A., 03 cada mes",
            "Banco Fie S.A., 03 cada mes",
            "Banco Union S.A., 13 cada mes",
            "Banco BCP S.A., 13 cada mes",
            "Banco SOL S.A., 17 cada mes",
            "Banco BNB S.A., 15 cada mes",
          ].map((text, index) => {
            const [beforeComma, afterComma] = text.split(",");
            return (
              <Box
                key={index}
                sx={{
                  display: "flex",  
                  alignItems: "flex-start",
                  marginBottom: "4px", // Ajusta el espacio entre líneas si es necesario
                }}
              >
                <p style={{ margin: 0, minWidth: "200px" }}>{beforeComma},</p>
                <p style={{ margin: 0, paddingLeft: "8px" }}>{afterComma}</p>
              </Box>
            );
          })}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
