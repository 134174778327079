import { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const ProductxTicket = lazy(() =>
  import("../pages/reports/reportTickets/productsxTicket/ProductxTicket")
);
const ReportProductForTicket = lazy(() =>
  import(
    "../pages/reports/reportTickets/productsxTicket/ReportProductForTicket"
  )
);
const StatisticsAnswer = lazy(() =>
  import("../pages/reports/reportStatistics/StatisticsAnswer")
);
const AssetList = lazy(() => import("../pages/inventory/assetList/AssetList"));
const PrintTraining = lazy(() =>
  import("../pages/ticket/ticketTraining/PrintTraining")
);
const TicketTraining = lazy(() =>
  import("../pages/ticket/ticketTraining/TicketTraining")
);
const CountProducts = lazy(() =>
  import("../pages/reports/reportProducts/countProducts/CountProducts")
);
const ProductPrices = lazy(() =>
  import("../pages/reports/reportProducts/ProductPrices/productPrices")
);
const TotalProduct = lazy(() =>
  import("../pages/reports/reportProducts/totalProduct/TotalProduct")
);

const TicketForm = lazy(() => import("../pages/ticket/ticketForm/TicketForm"));
const ReportListStockPdf = lazy(() =>
  import("../pages/inventory/stockProduct/reportListStockPdf")
);
const ProductEntryPrint = lazy(() =>
  import("../pages/inventory/productEntry/productEntryPrint")
);

const ListRepClientDep = lazy(() =>
  import("../pages/reports/reportClientTicket/listRep/ListRepClientDep")
);
const ListRepClient = lazy(() =>
  import("../pages/reports/reportClientTicket/listRepDep/ListRepClient")
);
const ListClientTicket = lazy(() =>
  import("../pages/reports/reportClientTicket/listTicket/listClientTicket")
);
const TicketsClient = lazy(() =>
  import("../pages/ticket/ticketsClients/TicketsClient")
);
const ListClientTicketDep = lazy(() =>
  import(
    "../pages/reports/reportClientTicket/listTicketDep/listClientTicketDep"
  )
);

const Staff = lazy(() => import("../pages/staff/Staff"));

const TicketsForCompany = lazy(() =>
  import("../pages/ticket/ticketsForCompany/TicketsForCompany")
);
const Tickets = lazy(() => import("../pages/ticket/tickets/Tickets"));
// const TicketForm = lazy(() => import("../pages/ticket/ticketForm/TicketForm"));
const GenerateTicket = lazy(() =>
  import("../pages/ticket/ticketForm/GenerateTicket")
);
const DetailsGeneralReport = lazy(() =>
  import("../pages/reports/reportTickets/generalReport/DetailsGeneralReport")
);
const GenerateGeneralReport = lazy(() =>
  import("../pages/reports/reportTickets/generalReport/GenerateGeneralReport")
);
const DetailsDepReport = lazy(() =>
  import("../pages/reports/reportTickets/depReport/DetailsDepReport")
);
const GenerateDepReport = lazy(() =>
  import("../pages/reports/reportTickets/depReport/GenerateDepReport")
);

const InfoEquipment = lazy(() =>
  import("../pages/reports/reportProducts/infoEquipment/infoEquipment")
);
const DetailsDepReportProduct = lazy(() =>
  import(
    "../pages/reports/reportProducts/depReportProduct/DetailsDepReportProduct"
  )
);
const DetailsGeneralReportProduct = lazy(() =>
  import(
    "../pages/reports/reportProducts/generalReportProduct/DetailsGeneralReportProduct"
  )
);
const GenerateGeneralReportProduct = lazy(() =>
  import(
    "../pages/reports/reportProducts/generalReportProduct/GenerateGeneralReportProduct"
  )
);
const GenerateDepReportProduct = lazy(() =>
  import(
    "../pages/reports/reportProducts/depReportProduct/GenerateDepReportProduct"
  )
);

const Provider = lazy(() => import("../pages/provider/Provider"));

const Inventory = lazy(() => import("../pages/inventory/inventory/Inventory"));
const ProductsEntry = lazy(() =>
  import("../pages/inventory/productEntry/productsEntry")
);
const ProductOutput = lazy(() =>
  import("../pages/inventory/productOutput/productOutput")
);
const StockProducts = lazy(() =>
  import("../pages/inventory/stockProduct/stockProducts")
);

const Agency = lazy(() => import("../pages/agency/agency"));
const Equipment = lazy(() => import("../pages/equipment/equipment"));
const Contact = lazy(() => import("../pages/contact/Contact"));
const Company = lazy(() => import("../pages/company/company"));
const EquipmentPricesModal = lazy(() =>
  import("../pages/company/equipmentPricesModal")
);
const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));
const User = lazy(() => import("../pages/user/user"));
const ListCardCompanies = lazy(() =>
  import("../pages/reports/reportTickets/generalReport/ListCardCompanies")
);
const content = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/user" element={<User />} />
        <Route path="/company" element={<Company />} />
        <Route path="/company/price/:id" element={<EquipmentPricesModal />} />
        <Route path="/agency" element={<Agency />} />
        <Route path="/equipment" element={<Equipment />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/provider" element={<Provider />} />
        <Route path="/ticketsForCompany" element={<TicketsForCompany />} />
        <Route path="/tickets" element={<Tickets />} />
        <Route path="/create/ticket" element={<TicketForm />} />
        <Route path="/ticket/edit/:id" element={<TicketForm />} />
        <Route path="/ticketPrint/:id" element={<GenerateTicket />} />
        <Route path="/assetList" element={<AssetList />} />
        <Route path="/inventory" element={<Inventory />} />
        <Route path="/productsEntry" element={<ProductsEntry />} />
        <Route
          path="/productEntryPrint/:codeFactura"
          element={<ProductEntryPrint />}
        />
        <Route path="/outputProduct" element={<ProductOutput />} />
        <Route path="/stockProduct" element={<StockProducts />} />
        <Route path="/reportListInventory" element={<ReportListStockPdf />} />
        <Route path="/generalReportTickets" element={<ListCardCompanies />} />
        <Route
          path="/detailsGeneralReport"
          element={<DetailsGeneralReport />}
        />
        <Route path="/generalReportView" element={<GenerateGeneralReport />} />
        <Route path="/depReportTickets" element={<ListCardCompanies />} />
        <Route path="/detailsDepReport" element={<DetailsDepReport />} />
        <Route path="/generateDepReport" element={<GenerateDepReport />} />

        <Route path="/infoEquipment" element={<InfoEquipment />} />
        <Route path="/generalReportProduct" element={<ListCardCompanies />} />
        <Route path="/depReportProduct" element={<ListCardCompanies />} />
        <Route path="/countProducts" element={<ListCardCompanies />} />
        <Route path="/detailsCountProducts" element={<CountProducts />} />
        <Route path="/infoEquipment" element={<InfoEquipment />} />
        <Route
          path="/detailsDepReportProduct"
          element={<DetailsDepReportProduct />}
        />
        <Route
          path="/detailsGeneralReportProduct"
          element={<DetailsGeneralReportProduct />}
        />
        <Route
          path="/reportGeneralProduct"
          element={<GenerateGeneralReportProduct />}
        />
        <Route
          path="/reportDepProduct"
          element={<GenerateDepReportProduct />}
        />
        <Route path="/reportproductPrices" element={<ProductPrices />} />

        <Route path="/ticketTraining" element={<TicketTraining />} />
        <Route path="/trainingPrint/:id" element={<PrintTraining />} />
        <Route path="/staff" element={<Staff />} />
        <Route path="/ticketForClient" element={<TicketsClient />} />
        <Route path="/listTicketClientDep" element={<ListClientTicketDep />} />
        <Route path="/listTicketClient" element={<ListClientTicket />} />
        <Route path="/listProductClientDep" element={<ListRepClientDep />} />
        <Route path="/listProductClient" element={<ListRepClient />} />
        <Route path="/totalProduct" element={<TotalProduct />} />

        <Route path="/productxticket" element={<ProductxTicket />} />
        <Route
          path="/reportProductForTicket/:id"
          element={<ReportProductForTicket />}
        />
        <Route path="/reportStatistics" element={<StatisticsAnswer />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
};

export default content;
